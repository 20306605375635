import Icon from '../../components/Icon.vue'

Icon.register({
  'brands/telegram-plane': {
    width: 448,
    height: 512,
    paths: [
      {
        d: 'M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1l-236 148.6-101.6-31.8c-22.1-6.9-22.5-22.1 4.6-32.7l397.4-153.1c18.4-6.9 34.5 4.1 28.5 32.2z'
      }
    ]
  }
})
